<template>
    <div class="container flex flex-col">
        <div class="mx-auto max-w-960 pt-16">
            <el-form
                :model="changePasswordForm"
                :rules="validationRules"
                ref="changePasswordForm"
                >
                <h1 class="text-black mr-2 mb-8 text-xl lg:text-2xl text-center" data-cy="accountSettingsTitle">Change Password</h1>

                <el-form-item prop="email" class="col-span-4">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Email</label>
                    <el-input v-model="this.$auth.user().email" placeholder="Email" disabled></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Password</label>
                    <el-input class="bg-white" v-model="changePasswordForm.password" placeholder="Password" type="password" show-password></el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Password</label>
                    <el-input class="bg-white" v-model="changePasswordForm.confirmPassword" placeholder="Confirm Password" type="password" show-password></el-input>
                </el-form-item>

                <div class="flex flex-row justify-center mt-8">
                    <el-button
                        class="font-bold uppercase w-40 font-display"
                        type="primary-lightest"
                        @click="goBack()"
                    >Cancel
                    </el-button>
                    <el-button
                        class="font-bold uppercase w-40 font-display"
                        type="success"
                        @click="validate()"
                    >Save
                    </el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HistoryFns from '@/components/shared/mixins/historyFns'
import { mapState } from 'vuex'

export default {
    name: "ChangePassword",
    mixins: [AuthMixin, MessageDialog, HistoryFns],
    data() {
        return {
            changePasswordForm: {
                password: '',
                confirmPassword: ''
            },
            validationRules: {
                password: [
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                    { min: 8, max: 16, message: 'Please enter 8 - 16 characters', trigger: 'blur' },
                ],
                confirmPassword: [
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                    { min: 8, max: 16, message: 'Please enter 8 - 16 characters', trigger: 'blur' },
                    { validator: this.validateConfirmPassword, trigger: 'blur' }
                ],
            },
            duration: 3000,
            defaultRoute: {
                name: 'PracticeList'
            },
        }
    },
    computed: {
        ...mapState({
            routeFrom: state => state.app.routeFrom,
        }),
    },
    methods: {
        validateConfirmPassword(rule, value, callback) {
            if (value !== this.changePasswordForm.password) {
                callback(new Error('Password does not match'));
            } else {
                callback();
            }
        },
        validate() {
            this.$refs.changePasswordForm.validate()
            .then(res => {
                this.$http({
                    url: `web.user/${this.userId}/`,
                    method: 'PATCH',
                    data: {
                        password: this.changePasswordForm.password
                    }
                }).then(res => {
                    this.message = 'Password has been updated.'
                    this.type = 'success'
                    this.resetChangePasswordForm()
                }).catch((err => {
                    this.message = 'Error updating your password'
                    this.type = 'error'
                })).finally(() => {
                    this.showMessage({duration: this.duration})
                })

            }).catch(err => {
            })
        },
        resetChangePasswordForm() {
            this.changePasswordForm.password = ''
            this.changePasswordForm.confirmPassword = ''
        }
    }
}
</script>
